import React from 'react'
import qr from 'qrcode'
import { Button } from '/features/buildingBlocks/Button'
import { HeadingXs } from '/features/buildingBlocks/Heading'

import styles from './QrWithDownloadButton.css'

export function QrWithDownloadButton({ url, title, buttonLabel }) {
  const { svg, download } = useQrCode(url)

  return (
    <div className={styles.component}>
      <div className={styles.qrLayout} dangerouslySetInnerHTML={{ __html: svg }} />
      <div className={cx(styles.content, styles.contentLayout)}>
        <HeadingXs h='4' layoutClassName={styles.titleLayout} {...{ title }} />

        <Button href={download} dataX="download-qr-code" layoutClassName={styles.buttonLayout} download>
          {buttonLabel}
        </Button>
      </div>
    </div>
  )
}

function useQrCode(url) {
  const [svg, setSvg] = React.useState('')
  const [download, setDownload] = React.useState('')

  React.useEffect(
    () => {
      qr.toString(url, { type: 'svg' }, (err, svg) => {
        setSvg(svg)

        const source = '<?xml version="1.0" standalone="no"?>\r\n' + svg
        setDownload('data:image/svg+xml;charset=utf-8,' + encodeURIComponent(source))
      })
    },
  )

  return { svg, download }
}
